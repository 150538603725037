import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Flex from "../atoms/Flex"
import { Text } from "../atoms/Typography"
import Button from "../atoms/Button"

const FilterTabs = ({
  menuOpen,
  options,
  anchorRef,
  name,
  id,
  allLabel,
  showFilterLabel,
  selected,
  alignMenuItems,
  location,
  setMenuOpen,
  setSelected,
  fromToggleList = false,
  filterTextDisplay,
  ...rest
}) => {
  const isSplit = location === "split" || location === "splitReverse"

  const handleChange = event => {
    if (!fromToggleList) {
      setSelected({ [id]: event.target.textContent })
    } else if (fromToggleList) {
      setSelected(event.target.textContent)
    }
  }

  const handleSelectedTabStyle = option => {
    return {
      variant: "link",
      fontWeight:
        option === selected?.[id]
          ? 900
          : fromToggleList && option === selected
          ? 900
          : 400,
      fontSize: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      ...(filterTextDisplay === "truncated" ? { maxWidth: "240px" } : {}), //when filterTextDisplay is "fixed", we restrict maxWidth to truncate text
      whiteSpace: "nowrap",
      display: "inline-block",
    }
  }

  const handleTabsLocation = location => {
    switch (location) {
      case "left":
      case "split":
        return "flex-start"
        break
      case "center":
        return "center"
        break
      case "right":
      case "splitReverse":
        return "flex-end"
        break
      default:
        return "flex-start"
    }
  }

  return (
    <Flex
      flexDirection="column"
      alignItems={{
        _: handleTabsLocation(isSplit ? "center" : location),
        md: "flex-start",
      }}
    >
      {showFilterLabel && (
        <Text fontSize={1} color="text">
          {name}
        </Text>
      )}
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent={{
          _: handleTabsLocation(isSplit ? "center" : location),
          md: handleTabsLocation(location),
        }}
        width="100%"
      >
        {options?.map((option, i) => {
          const conditionalProps = {}
          switch (location) {
            case "right":
              conditionalProps.ml = i === 0 ? 0 : 6
              break
            case "splitReverse":
              conditionalProps.mr = { _: 4, md: 0 }
              conditionalProps.ml = { _: i === 0 ? 0 : 4, md: i === 0 ? 0 : 6 }
              break
            case "center":
              conditionalProps.mr = 4
              conditionalProps.ml = i === 0 ? 0 : 4
              break
            case "left":
              conditionalProps.mr = i === options.length - 1 ? 0 : 6
              break
            case "split":
              conditionalProps.mr = {
                _: 4,
                md: i === options.length - 1 ? 0 : 6,
              }
              conditionalProps.ml = { _: i === 0 ? 0 : 4, md: 0 }
              break
            default:
              conditionalProps.mr = i === options.length - 1 ? 0 : 6
          }

          return (
            <Button
              aria-label="Filter tab button"
              key={i}
              ref={anchorRef}
              onClick={!fromToggleList ? handleChange : () => setSelected(i)}
              mt={4}
              variant="link"
              hideArrow={true}
              {...rest}
              {...conditionalProps}
            >
              <Text {...handleSelectedTabStyle(option)}>{option}</Text>
            </Button>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default FilterTabs

FilterTabs.strapiProps = {
  name: PropTypes.string,
  allLabel: PropTypes.string.isRequired,
  showFilterLabel: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.object),
}

export const query = graphql`
  fragment FilterTabs on STRAPI__COMPONENT_ATOMS_FILTER {
    name
    allLabel
    showFilterLabel
    tags {
      name
    }
  }
`
